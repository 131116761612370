<template>
  <div id="page-lap-pelayanan-harian">
    <b-container fluid>
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
          <b-card>
            <!-- <h1>DALAM PENGEMBANGAN</h1> -->
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Dari
                  </template>
                  <date-picker
                    style="width: 100%"
                    id="tanggal"
                    format="MMMM - YYYY"
                    type="month"
                    v-model="dataSearch.tanggal_awal"
                    @change="getSearch()"
                  ></date-picker>
                </b-form-group>
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Sampai
                  </template>
                  <date-picker
                    style="width: 100%"
                    id="tanggal"
                    format="MMMM - YYYY"
                    type="month"
                    v-model="dataSearch.tanggal_akhir"
                    @change="getSearch()"
                  ></date-picker>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div style="width:100%;display:flex">
                  <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                    <h6 style="margin:0;font-weight:bold">Puskesmas</h6>
                  </div>
                  <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                    <b-button variant="outline-primary" size="sm">
                      <b-form-checkbox
                        id="check_puskesmas"
                        name="check_puskesmas"
                        v-model="checkedPuskesmas"
                        @change="toggleSelectPuskesmas()"
                      >
                        <span class="pt-1">Pilih Semua</span>
                      </b-form-checkbox>
                    </b-button>
                  </div>
                </div>
                <hr>
                <b-row style="padding-left:7.5px;margin-top:15px;">
                  <b-col v-for="(item, idx) in listPuskesmas" :key="idx" cols="12" sm="12" md="12" lg="6" xl="6">
                      <b-form-checkbox
                        v-model="item.checked"
                      >
                        {{ item.nama_puskesmas }}
                      </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div class="d-flex">
                  <b-button @click="getSearch()" variant="primary" class="mr-2">Tampilkan</b-button>
                  <b-button v-if="onUpdate" @click="$bvModal.show('modal-update-infeksi-menular-seksual')" variant="warning" class="mr-2">Update</b-button>
                  <b-button v-else @click="$bvModal.show('modal-update-infeksi-menular-seksual')" variant="primary" class="mr-2">Buat Baru</b-button>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <h4 style="text-align:center"><strong>LAPORAN BULANAN - INFEKSI MENULAR SEKSUAL (IMS)</strong></h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" md="6" lg="6">
                <b-form-group label-cols-md="7">
                  <template v-slot:label>
                    Jumlah Puskesmas Pembantu yang ada
                  </template>
                  <b-form-input 
                    type="number" 
                    v-model="dataForm.jumlah_pustu"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-cols-md="7">
                  <template v-slot:label>
                    Jumlah Poskesdes/Bidan di Desa
                  </template>
                  <b-form-input 
                    type="number" 
                    v-model="dataForm.jumlah_poskesdes"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="6" lg="6">
                <b-form-group label-cols-md="7">
                  <template v-slot:label>
                    Jumlah Puskesmas Pembantu yang lapor
                  </template>
                  <b-form-input 
                    type="number" 
                    v-model="dataForm.jumlah_pustu_lapor"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-cols-md="7">
                  <template v-slot:label>
                    Jumlah Poskesdes/Bidan yang lapor
                  </template>
                  <b-form-input 
                    type="number" 
                    v-model="dataForm.jumlah_poskesdes_lapor"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <b-table-simple responsive class="table-lap-pelayanan-harian">
                  <b-thead>
                    <b-tr class="text-center">
                      <b-th rowspan="2" style="min-width: 30px;">No</b-th>
                      <b-th rowspan="2" style="min-width: 200px;">Nama</b-th>
                      <b-th colspan="7" style="min-width: 100px;">Laki - Laki	</b-th>
                      <b-th colspan="7" style="min-width: 100px;">Perempuan	</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Total L/P</b-th>
                      <b-th colspan="9" style="min-width: 100px;">Kelompok Resiko</b-th>
                    </b-tr>
                    <b-tr class="text-center">
                      <b-th rowspan="2" style="min-width: 100px;">&lt; 1</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">1 - 14</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">15 - 19</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">20 - 24</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">25 - 49</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">&gt;= 50</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">&lt; 1</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">1 - 14</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">15 - 19</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">20 - 24</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">25 - 49</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">&gt;= 50</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">WPS</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">PPS</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Waria</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">LSL</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">IDU</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Pasangan Risti</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Pelanggan PS</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Lain-lain</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                    </b-tr>
                  </b-thead>
                  
                  <b-tbody v-if="dataForm.data_ims['Utama']">
                    <b-tr v-for="(value, key, i) in is_real['Utama']" :key="key">
                      <b-td class="text-center">{{ i+1 }}</b-td>
                      <b-td>{{ key }}</b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real['Utama'][key].laki_laki" :key="'01'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_ims['Utama'][key].laki_laki.jumlah }}</b></div>
                        <div v-else type="number">{{dataForm.data_ims['Utama'][key].laki_laki[key2]}}</div>
                      </b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real['Utama'][key].perempuan" :key="'02'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_ims['Utama'][key].perempuan.jumlah }}</b></div>
                        <div v-else type="number">{{dataForm.data_ims['Utama'][key].perempuan[key2]}}</div>
                      </b-td>
                      <b-td class="text-center"><b>{{ dataForm.data_ims['Utama'][key].total_lp }}</b></b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real['Utama'][key].kelompok_resiko" :key="'03'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_ims['Utama'][key].kelompok_resiko.jumlah }}</b></div>
                        <div v-else type="number">{{dataForm.data_ims['Utama'][key].kelompok_resiko[key2]}}</div>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="total['Utama']">
                      <b-td colspan="2" class="text-center"><b>Total</b></b-td>
                      <b-td class="text-center" v-for="(value2, key2) in total['Utama'].laki_laki" :key="'01'+key2">
                        <div><b>{{ total['Utama'].laki_laki[key2] }}</b></div>
                      </b-td>
                      <b-td class="text-center" v-for="(value2, key2) in total['Utama'].perempuan" :key="'02'+key2">
                        <div><b>{{ total['Utama'].perempuan[key2] }}</b></div>
                      </b-td>
                      <b-td class="text-center"><b>{{ total['Utama'].total_lp }}</b></b-td>
                      <b-td class="text-center" v-for="(value2, key2) in total['Utama'].kelompok_resiko" :key="'03'+key2">
                        <div><b>{{ total['Utama'].kelompok_resiko[key2] }}</b></div>
                      </b-td>
                    </b-tr>
                    <tr>
                      <b-td colspan="29">
                        <h6 style="font-weight: 700; color: #9c4098; font-size: 16px; margin-bottom: 0">
                          Pendekatan Sindrom / Klinis
                        </h6>
                      </b-td>
                    </tr>
                    <b-tr v-for="(value, key, i) in is_real['Pendekatan Sindrom / Klinis']" :key="key">
                      <b-td class="text-center">{{ i+1 }}</b-td>
                      <b-td>{{ key }}</b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real['Pendekatan Sindrom / Klinis'][key].laki_laki" :key="'01'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].laki_laki.jumlah }}</b></div>
                        <div v-else type="number">{{ dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].laki_laki[key2] }}</div>
                      </b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real['Pendekatan Sindrom / Klinis'][key].perempuan" :key="'02'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].perempuan.jumlah }}</b></div>
                        <div v-else type="number">{{ dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].perempuan[key2] }}</div>
                      </b-td>
                      <b-td class="text-center"><b>{{ dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].total_lp }}</b></b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real['Pendekatan Sindrom / Klinis'][key].kelompok_resiko" :key="'03'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].kelompok_resiko.jumlah }}</b></div>
                        <div v-else type="number">{{ dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].kelompok_resiko[key2] }}</div>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="total['Pendekatan Sindrom / Klinis']">
                      <b-td colspan="2" class="text-center"><b>Total</b></b-td>
                      <b-td class="text-center" v-for="(value2, key2) in total['Pendekatan Sindrom / Klinis'].laki_laki" :key="'01'+key2">
                        <div><b>{{ total['Pendekatan Sindrom / Klinis'].laki_laki[key2] }}</b></div>
                      </b-td>
                      <b-td class="text-center" v-for="(value2, key2) in total['Pendekatan Sindrom / Klinis'].perempuan" :key="'02'+key2">
                        <div><b>{{ total['Pendekatan Sindrom / Klinis'].perempuan[key2] }}</b></div>
                      </b-td>
                      <b-td class="text-center"><b>{{ total['Pendekatan Sindrom / Klinis'].total_lp }}</b></b-td>
                      <b-td class="text-center" v-for="(value2, key2) in total['Pendekatan Sindrom / Klinis'].kelompok_resiko" :key="'03'+key2">
                        <div><b>{{ total['Pendekatan Sindrom / Klinis'].kelompok_resiko[key2] }}</b></div>
                      </b-td>
                    </b-tr>
                    <tr>
                      <b-td colspan="29">
                        <h6 style="font-weight: 700; color: #9c4098; font-size: 16px; margin-bottom: 0">
                          Pendekatan Laboratorium
                        </h6>
                      </b-td>
                    </tr>
                    <b-tr v-for="(value, key, i) in is_real['Pendekatan Laboratorium']" :key="key">
                      <b-td class="text-center">{{ i+1 }}</b-td>
                      <b-td>{{ key }}</b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real['Pendekatan Laboratorium'][key].laki_laki" :key="'01'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_ims['Pendekatan Laboratorium'][key].laki_laki.jumlah }}</b></div>
                        <div v-else type="number">{{ dataForm.data_ims['Pendekatan Laboratorium'][key].laki_laki[key2] }}</div>
                      </b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real['Pendekatan Laboratorium'][key].perempuan" :key="'02'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_ims['Pendekatan Laboratorium'][key].perempuan.jumlah }}</b></div>
                        <div v-else type="number">{{ dataForm.data_ims['Pendekatan Laboratorium'][key].perempuan[key2] }}</div>
                      </b-td>
                      <b-td class="text-center"><b>{{ dataForm.data_ims['Pendekatan Laboratorium'][key].total_lp }}</b></b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real['Pendekatan Laboratorium'][key].kelompok_resiko" :key="'03'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_ims['Pendekatan Laboratorium'][key].kelompok_resiko.jumlah }}</b></div>
                        <div v-else type="number">{{ dataForm.data_ims['Pendekatan Laboratorium'][key].kelompok_resiko[key2] }}</div>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="total['Pendekatan Laboratorium']">
                      <b-td colspan="2" class="text-center"><b>Total</b></b-td>
                      <b-td class="text-center" v-for="(value2, key2) in total['Pendekatan Laboratorium'].laki_laki" :key="'01'+key2">
                        <div><b>{{ total['Pendekatan Laboratorium'].laki_laki[key2] }}</b></div>
                      </b-td>
                      <b-td class="text-center" v-for="(value2, key2) in total['Pendekatan Laboratorium'].perempuan" :key="'02'+key2">
                        <div><b>{{ total['Pendekatan Laboratorium'].perempuan[key2] }}</b></div>
                      </b-td>
                      <b-td class="text-center"><b>{{ total['Pendekatan Laboratorium'].total_lp }}</b></b-td>
                      <b-td class="text-center" v-for="(value2, key2) in total['Pendekatan Laboratorium'].kelompok_resiko" :key="'03'+key2">
                        <div><b>{{ total['Pendekatan Laboratorium'].kelompok_resiko[key2] }}</b></div>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- {{listRujukInternal}} -->
    </b-container>
    <ModalUpdate
      :onUpdate="onUpdate"
      :dataSearch="dataSearch"
      :is_data="dataForm"
      :is_real="is_real"
      @alertFromChild="triggerAlert($event), getSearch()"
    />
  </div>
</template>
  
<script>
import ModalUpdate from "./modal_update.vue";
export default {
  components: {
    ModalUpdate,
  },
  name: "kunjungan_pasien",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      kunjungan_5: false,
      dataSearch: {
        tanggal_awal: null,
        tanggal_akhir: null,
      },
      listRujukInternal: [],
      kosong: false,
      dataForm: {
        tanggal: this.tanggal_sesi,
        bulan_ims: null,
        tahun_ims: null,
        jumlah_pustu: 0,
        jumlah_poskesdes: 0,
        jumlah_pustu_lapor: 0,
        jumlah_poskesdes_lapor: 0,
        data_ims: {},
      },
      is_real: {},
      total : {},
      listPuskesmas: [],
      checkedPuskesmas: false,
      items: [],
      filter: null,
      tableBusy: false,
      disabled_field: false,
    };
  },
  computed: {
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
    },
    onUpdate(){
      return this.$moment(this.dataSearch.tanggal_awal).format('MM-YYYY') == this.$moment(this.dataSearch.tanggal_akhir).format('MM-YYYY') && !this.kosong
    }
  },
  activated(){
    const vm = this
    vm.dataForm.tanggal = new Date(vm.tanggal_sesi)
    let head = {
      'Utama': {
        'Jumlah Kunjungan layanan IMS': {},
        'Jumlah pasien baru yang berkunjung': {},
        'Jumlah Pasien yang berkunjung': {},
        'Jumlah Pasien IMS yang ditemukan': {},
        'Jumlah Pasien IMS yang diobati': {},
        'Jumlah Kasus IMS yang ditemukan': {},
        'Jumlah Kasus IMS yang diobati': {},
        'Jumlah Pasien yang dites sifilis': {},
        'Jumlah pasien sifilis yang diobati': {},
        'Jumlah orang yang diberi kondom': {},
        'Jumlah kondom yang diberikan': {},
        'Jumlah pasien yang dirujuk ke layanan konseling dan tes HIV': {},
        'Jumlah bumil yang berkunjung pertama kali (K1) ke ANC': {},
        'Jumlah bumil yang berkunjung pertama kali (K1) ke ANC dan dites sifilis': {},
        'Jumlah bumil yang sifilis': {},
        'Jumlah bumil sifilis yang diobati': {},
        'Jumlah pasien yang berkunjung bulan ini yang dirujuk oleh LSM': {},
      },
      'Pendekatan Sindrom / Klinis': {
        'Duh tubuh vagina': {},
        'Duh tubuh uretra': {},
        'Ulkus Genital': {},
        'Bubo inguinal': {},
        'Penyakit radang panggul': {},
        'Pembengkakan skrotum': {},
        'Tumbuhan Genital/Vegetasi': {},
        'Konjungtivitis neonatorum': {},
        'Duh tubuh Anus': {},
      },
      'Pendekatan Laboratorium': {
        'Sifilis Dini': {},
        'Sifilis Lanjut': {},
        'Gonore': {},
        'Urethritis Gonore': {},
        'Urethritis Non-GO': {},
        'Servisitis Proctitis': {},
        'LGV': {},
        'Trikomoniasis': {},
        'Herpes Genital': {},
      }
    }
    const item = {
      'laki_laki': {
        '1': 0,
        '1_14': 0,
        '15_19': 0,
        '20_24': 0,
        '25_49': 0,
        '50': 0,
        jumlah: 0,
      },
      'perempuan': {
        '1': 0,
        '1_14': 0,
        '15_19': 0,
        '20_24': 0,
        '25_49': 0,
        '50': 0,
        jumlah: 0,
      },
      'kelompok_resiko': {
        wps: 0,
        pps: 0,
        waria: 0,
        lsl: 0,
        idu: 0,
        pasangan_risti: 0,
        pelanggan_ps: 0,
        lain_lain: 0,
        jumlah: 0,
      },
      total_lp: 0
    }
    for (const keyHead in head) {
      for (const keySub in head[keyHead]) {
        head[keyHead][keySub] = item
      }
    }
    // console.log('head', head)
    vm.is_real = head
    vm.dataForm.data_ims = head
    vm.getData()
    vm.reset()
  },
  methods: {
    // menghitung column / kolom
    async countTotal(){
      const vm = this
      const total = {}
      const data = JSON.parse(JSON.stringify(vm.dataForm.data_ims))
      for (const key1 in data) {
        if(!total[key1]){
          total[key1] = {}
        }
        for (const key2 in data[key1]) {
          for (const key3 in data[key1][key2]) {
            if(total[key1][key3] === undefined) {
              if(key3 == 'total_lp'){
                total[key1][key3] = 0
              }else{
                total[key1][key3] = {}
              }
            }
            if(key3 == 'total_lp'){
              // console.log(key3, total[key1][key3], '-', data[key1][key2][key3])
              if(total[key1][key3] === undefined || total[key1][key3] == {}) {
                total[key1][key3] = Number.parseInt(data[key1][key2][key3])
              }else{
                total[key1][key3] += Number.parseInt(data[key1][key2][key3])
              }
            }else{
              for (const key4 in data[key1][key2][key3]) {
                if(!total[key1][key3][key4]) {
                  total[key1][key3][key4] = Number.parseInt(data[key1][key2][key3][key4])
                }else{
                  total[key1][key3][key4] += Number.parseInt(data[key1][key2][key3][key4])
                }
              }
            }
          }
        } 
      }
      vm.total = total
      // console.log('total', total)
    },
    async toggleSelectPuskesmas () {
      const vm = this
      vm.listPuskesmas.forEach(function (data) {
        data.checked = vm.checkedPuskesmas;
      });
      vm.$forceUpdate()
    },
    async getData(){
      const vm = this
      vm.tableBusy = false
      try {
        const listPoli = await vm.$axios.post('/ms_poli/list')
        vm.listPoli = listPoli.data.status == 200 ? listPoli.data.data : []
        const listPuskesmas = await vm.$axios.post('/puskesmas/list')
        vm.listPuskesmas = listPuskesmas.data.status == 200 ? listPuskesmas.data.data : []
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async getSearch(){
      const vm = this
      vm.tableBusy = false
      try {
        //puskesmas
        vm.dataForm.tanggal = null
        const bulk_puskesmas_id = []
        for (let i = 0; i < vm.listPuskesmas.length; i++) {
          const puskesmas = vm.listPuskesmas[i];
          if(puskesmas.checked){
            bulk_puskesmas_id.push(puskesmas.id)
          }
        }
        const res = await vm.$axios.post('/pemeriksaan_ims/laporan_pemeriksaan_ims', {
          dari_bulan: vm.$moment(vm.dataSearch.tanggal_awal).format('MM'),
          dari_tahun: vm.$moment(vm.dataSearch.tanggal_awal).format('YYYY'),
          sampai_bulan: vm.$moment(vm.dataSearch.tanggal_akhir).format('MM'),
          sampai_tahun: vm.$moment(vm.dataSearch.tanggal_akhir).format('YYYY'),
          bulk_puskesmas_id,
        })
        // console.log('pemeriksaan_ims/laporan_pemeriksaan_ims', res)
        if(res.data.status == 200 && res.data.data){
          vm.dataForm = res.data.data
          vm.countTotal()
          // console.log('vm.dataForm', vm.dataForm)
          vm.kosong = false
          if(vm.onUpdate){
            vm.dataForm.tanggal = new Date(vm.dataSearch.tanggal_awal)
          }
        } else {
          vm.triggerAlert({ variant: "success", msg: res.data.message, showing: true });
          vm.dataForm = {
            tanggal: this.tanggal_sesi,
            bulan_ims: null,
            tahun_ims: null,
            jumlah_pustu: 0,
            jumlah_poskesdes: 0,
            jumlah_pustu_lapor: 0,
            jumlah_poskesdes_lapor: 0,
            data_ims: vm.is_real,
          }
          vm.kosong = true
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async reset(){
      const vm = this
      vm.tableBusy = false
      vm.dataSearch = {
        tanggal_awal:  new Date(vm.$moment(vm.$store.state.tanggal_sesi)),
        tanggal_akhir: new Date(vm.$moment(vm.$store.state.tanggal_sesi)),
      }
      vm.getSearch()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* .table-lap-pelayanan-harian th{ */
#page-lap-pelayanan-harian .table-lap-pelayanan-harian th{
  background-color: #D52F65 !important ;
  color: #fff;
  font-weight: 600;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:first-child {
  border-top-left-radius: 8px;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:last-child {
  border-top-right-radius: 8px;
}
</style>
